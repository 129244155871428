import { faBars, faCog, faSignOutAlt, faTachometerAlt, faTimes, faUsers , faTrash} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import Loader from '../Loader/Loader';
import './AdminHome.css';

function AdminHome() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const handleLogout = async () => {
    try {
      // Invalidate the session on the server
      await fetch('https://realstock.onrender.com/api/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      // Clear user authentication data (e.g., tokens)
      localStorage.removeItem('authToken');

      // Redirect to login page
      window.location.href = '/Admin';
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  useEffect(() => {
    // Fetch data from the URL
    const fetchData = async () => {
      try {
        const response = await fetch('https://realstock.onrender.com/api/userData', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
        });
        const data = await response.json();
        setContacts(data); // Assuming the data is an array
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false); 
      }
    };

    fetchData();
  }, []);




    // Function to handle delete action
    const handleDelete = async (id) => {
      try {
        const response = await fetch(`https://realstock.onrender.com/api/userData/${id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
        });
  
        if (response.ok) {
          // Remove the deleted contact from the state
          setContacts(contacts.filter(contact => contact._id !== id));
        } else {
          console.error('Error deleting contact:', await response.json());
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
  




  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(contacts);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Contacts');
    XLSX.writeFile(workbook, 'contacts.xlsx');
  };

  return (
    <div className="admin-container">
      {/* Sidebar Toggle Button */}
      <button className="nav-toggle" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={isSidebarOpen ? faTimes : faBars} />
      </button>

      {/* Sidebar for Smaller Screens */}
      <nav className={`admin-nav ${isSidebarOpen ? 'open' : ''}`}>
        <h2>Admin Panel</h2>
        <ul>
          <li>
            <a href="#dashboard">
              <FontAwesomeIcon icon={faTachometerAlt} className="nav-icon" />
              Dashboard
            </a>
          </li>
          <li>
            <a href="#users">
              <FontAwesomeIcon icon={faUsers} className="nav-icon" />
              Users
            </a>
          </li>
          <li>
            <a href="#settings">
              <FontAwesomeIcon icon={faCog} className="nav-icon" />
              Settings
            </a>
          </li>
          <li>
            <button onClick={handleLogout} className="nav-logout">
              <FontAwesomeIcon icon={faSignOutAlt} className="nav-icon" />
              Logout
            </button>
          </li>
        </ul>
      </nav>

      {/* Side Navigation for Large Screens */}
      <div className="side-nav">
        <h2>Admin Panel</h2>
        <ul>
          <li>
            <a href="#dashboard">
              <FontAwesomeIcon icon={faTachometerAlt} className="nav-icon" />
              Dashboard
            </a>
          </li>
          <li>
            <a href="#users">
              <FontAwesomeIcon icon={faUsers} className="nav-icon" />
              Users
            </a>
          </li>
          <li>
            <a href="#settings">
              <FontAwesomeIcon icon={faCog} className="nav-icon" />
              Settings
            </a>
          </li>
          <li>
            <a href='#' onClick={handleLogout} className="nav-logout">
              <FontAwesomeIcon icon={faSignOutAlt} className="nav-icon" />
              Logout
            </a>
          </li>
        </ul>
      </div>

      <div className={`admin-content ${isSidebarOpen ? 'nav-open' : ''}`}>
        <h1>Welcome to the Admin Panel</h1>
        <p style={{ color: 'white' }}>Here you can manage your application settings and data.</p>
        
        {/* Button to Export Data to Excel */}
        <button onClick={exportToExcel} style={{padding:'5px', background:'green', color:'white', borderRadius:'5px'}}>Export to Excel</button>

        {/* Display fetched data in a scrollable list */}
 {/* Show loader if data is still loading */}
 {loading ? (
          <Loader />
        ) : (
          <div className="contacts">
            
            <ol className="contact-list">
              {contacts.length > 0 ? (
                contacts.map(contact => (
                  <li key={contact._id} className="contact-list-item">
                     <div>
                     <strong>Name:</strong> {contact._id}<br />
                    <strong>Name:</strong> {contact.name}<br />
                    <strong>Email:</strong> {contact.email}<br />
                    <strong>Phone:</strong> {contact.phone}<br />
                    <strong>Segment:</strong> {contact.segment}<br />
                    <strong>Investment:</strong> {contact.investment}<br />
                    {/* <strong>Message:</strong> {contact.message}<br /> */}
                    <strong>Date:</strong> {new Date(contact.date).toLocaleDateString()}
                    </div>
                    <FontAwesomeIcon 
                    icon={faTrash} color='yellow'
                     className="nav-icon"
                     onClick={() => handleDelete(contact._id)}
                      />
                  </li>
                  
                  
                ))
              ) : (
                <li style={{ color: 'white' }}>No contacts available.</li>
              )}
            </ol>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminHome;
