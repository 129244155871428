import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Button,
  Collapse,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import companylogo from '../images/companylogo.jpeg';

const MainHeader = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false);
  const [insightsOpen, setInsightsOpen] = useState(false); // State to manage Insights submenu
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const menuItems = [
    { text: 'Home', link: '/' },
    { text: 'About', link: '/about' },
    {
      text: 'Services',
      submenu: [
        { text: 'Equity', link: 'equity' },
        { text: 'Derivatives', link: '/derivative' },
      ],
    },
    {
      text: 'Insights', // New menu item
      submenu: [
        { text: 'Tracksheet', link: '/track-sheet' },
        { text: 'Complaint Status', link: '/complaint-status' },
        { text: "FAQ's", link: '/faq' },
        { text: 'Disclosure with Advice', link: '/disclosures-advise' },
      ],
    },
    { text: 'Contact', link: '/contact' },
  ];

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleServicesToggle = () => {
    setServicesOpen(!servicesOpen);
  };

  const handleInsightsToggle = () => {
    setInsightsOpen(!insightsOpen);
  };

  const drawer = (
    <Box
      sx={{
        width: 250,
        background: 'linear-gradient(45deg, #6d28d9 30%, #3b82f6 90%)',
        color: '#fff',
        height: '100%',
      }}
      role="presentation"
      onClick={(e) => e.stopPropagation()} // Prevent closing the drawer when clicking inside
    >
      <List>
        {menuItems.map((item, index) =>
          item.submenu ? (
            <React.Fragment key={index}>
              <ListItem button onClick={item.text === 'Services' ? handleServicesToggle : handleInsightsToggle}>
                <ListItemText 
                  primary={item.text} 
                  sx={{ color: '#fff', fontSize: '1.1rem', fontWeight: 'bold' }} // Larger, bold font
                />
                {(item.text === 'Services' ? servicesOpen : insightsOpen) ? (
                  <ExpandLessIcon sx={{ color: '#fff' }} />
                ) : (
                  <ExpandMoreIcon sx={{ color: '#fff' }} />
                )}
              </ListItem>
              <Collapse in={item.text === 'Services' ? servicesOpen : insightsOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.submenu.map((subitem, subindex) => (
                    <ListItem
                      button
                      key={subindex}
                      onClick={() => {
                        window.location.href = subitem.link;
                        setDrawerOpen(false); // Close the drawer on submenu item click
                      }}
                      sx={{
                        pl: 4,
                        color: '#fff',
                        '&:hover': {
                          backgroundColor: '#3b82f6',
                          color: '#fff',
                        },
                      }}
                    >
                      <ListItemText 
                        primary={subitem.text} 
                        sx={{ fontSize: '1rem', fontWeight: 'bold' }} // Bold submenu text
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          ) : (
            <ListItem
              button
              key={index}
              onClick={() => {
                window.location.href = item.link;
                setDrawerOpen(false); // Close the drawer on menu item click
              }}
              sx={{
                color: '#fff',
                '&:hover': { backgroundColor: '#3b82f6' },
              }}
            >
              <ListItemText 
                primary={item.text} 
                sx={{ fontSize: '1.1rem', fontWeight: 'bold' }} // Larger, bold font
              />
            </ListItem>
          )
        )}
      </List>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{
          background: 'linear-gradient(45deg, #6d28d9 30%, #3b82f6 90%)',
          color: '#fff',
        }}
      >
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item xs={4} md={3}>
              <Box
                component="img"
                src={companylogo} // Adjust the path to your logo image
                alt="Logo"
                sx={{
                  height: 40, // Adjust the height as needed
                  marginRight: 2, // Add some space between the logo and the title/menu
                  borderRadius: 10,
                }}

                onClick={() => window.location.href = '/'}
              />
            </Grid>

            {isMobile ? (
              <>
                <Grid item xs={8} textAlign="right">
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleDrawerToggle}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
                <Drawer
                  anchor="left"
                  open={drawerOpen}
                  onClose={handleDrawerToggle}
                >
                  {drawer}
                </Drawer>
              </>
            ) : (
              <Grid item xs={8} md={6} sx={{ textAlign: 'center' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  {menuItems.map((item, index) =>
                    item.submenu ? (
                      <Box key={index} sx={{ position: 'relative', ml: 2 }}>
                        <Button
                          color="inherit"
                          aria-haspopup="true"
                          onClick={item.text === 'Services' ? handleServicesToggle : handleInsightsToggle}
                          sx={{
                            position: 'relative',
                            fontSize: '1.1rem', // Larger font size
                            fontWeight: 'bold', // Bold text
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}
                        >
                          {item.text}
                          {(item.text === 'Services' ? servicesOpen : insightsOpen) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </Button>
                        {isLargeScreen && (item.text === 'Services' ? servicesOpen : insightsOpen) && (
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '100%', // Position the submenu directly below the button
                              left: 0,
                              backgroundColor: theme.palette.background.paper,
                              boxShadow: theme.shadows[4],
                              zIndex: theme.zIndex.appBar + 1,
                              minWidth: '150px',
                              borderRadius: '4px',
                              mt: 1, // Slight gap from the button
                              overflow: 'hidden',
                            }}
                          >
                            <List sx={{ p: 0 }}>
                              {item.submenu.map((subitem, subindex) => (
                                <ListItem
                                  button
                                  key={subindex}
                                  sx={{
                                    width: '100%',
                                    color: '#000', // Set text color to black
                                    fontSize: '1rem', // Adjusted font size
                                    fontWeight: 'bold', // Bold text
                                    '&:hover': {
                                      background:
                                        'linear-gradient(45deg, #6d28d9 30%, #3b82f6 90%)',
                                      color: '#fff',
                                    },
                                  }}
                                  onClick={() =>
                                    (window.location.href = subitem.link)
                                  }
                                >
                                  <ListItemText primary={subitem.text} />
                                </ListItem>
                              ))}
                            </List>
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <Button
                        key={index}
                        color="inherit"
                        sx={{ ml: theme.spacing(2), fontSize: '1.1rem', fontWeight: 'bold' }} // Larger, bold font
                        onClick={() => (window.location.href = item.link)}
                      >
                        {item.text}
                      </Button>
                    )
                  )}
                </Box>
              </Grid>
            )}

            {!isMobile && (
              <Grid item xs={2} textAlign="right">
                {/* You can add additional buttons or icons here */}
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default MainHeader;
